import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Mousewheel, Navigation } from "swiper";
import {
  useElementSize,
  useMediaQuery,
  useIntersectionObserver,
} from "usehooks-ts";

import Section from "./section";
import DonateCard from "../components/donate-card";
import ButtonModal from "../components/button-modal";
import LayoutHorizontal from "./layout-horizontal";

import MemoryLongTextModal from "./modal/memory-longtext";
import ReportFormModal from "./modal/report";
import ShareFormModal from "./modal/share";
import MemoryImageModal from "./modal/memory-image";

const maxLength = 400;
const maxLengthMobile = 175;

const SingleObituary = (props: any) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [memoryModalImageItem, setMemoryModalImageItem] = useState();
  const [showMemoryModal, setShowMemoryModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [memoryModalItem, setMemoryModalItem] = useState();
  const [reportModalItem, setReportModalItem] = useState();
  const [dataObituary, setDataObituary] = useState();
  const noFund = ["Not decided", "No memorial fund", "Ej bestämt"];
  const matches = useMediaQuery("(min-width: 1200px)");
  const [evenObituaryList, setEvenObituaryList] = useState([]);
  const [oddObituaryList, setOddObituaryList] = useState([]);
  const [slideWidth, setSlideWidth] = useState("600px");
  const [slideHeight, setSlideHeight] = useState(600);
  const [memoriesHeight, setMemoriesHeight] = useState([]);
  const [userLikes, setUserLikes] = useState();
  const [likesArray, setLikesArray] = useState<any[]>([]);
  const [userReports, setUserReports] = useState();
  const [userMemories, setUserMemories] = useState();
  const obituaryRefsMobile = useRef([]);

  useEffect(() => {
    if (dataObituary == null) {
      const reportsExistant: any = window.localStorage.getItem(
        "fenix_memory_reports"
      );
      reportsExistant && setUserReports(reportsExistant);
      const likesExistant: any =
        window.localStorage.getItem("fenix_memory_likes");
      likesExistant && setUserLikes(likesExistant);
      const memoriesExistant: any =
        window.localStorage.getItem("fenix_memories");
      memoriesExistant && setUserMemories(memoriesExistant);

      fetch(
        process.env.FENIX_LOCATION_AJAX_URL +
          "_seo-scripts/memorial/memorials.json"
      )
        .then((response) => {
          if (response) {
            // console.log("response", response);
            return response.json();
          }
          throw new Error("Fetch error");
        })
        .then((memorialsDataIda: any) => {
          const dataObituaryData = memorialsDataIda?.list.filter(
            (oneItem) => oneItem.url == "/memorial/" + props.memoryId + "/"
          )[0];
          props.setmeta(dataObituaryData);
          if (dataObituaryData?.memories?.items) {
            for (let i = 0; i < dataObituaryData.memories.items.length; i++) {
              dataObituaryData.memories.items[i].itemIndex = i + 1;
            }
          }
          console.log(dataObituaryData);
          document.title =
            "In memory of " +
            dataObituaryData.name +
            " - Fenix Funeral Directors";
          setSlideWidth(
            (dataObituaryData?.memories?.items?.length / 1.7) * 750 +
              size2.width +
              size3.width +
              "px"
          );
          setDataObituary(dataObituaryData);
          const evenObituaryListData =
            dataObituaryData?.memories?.items &&
            dataObituaryData.memories.items
              .sort((a, b) => a.ida_id - b.ida_id)
              .filter(function (element, index, array) {
                return index % 2 === 0;
              });
          setEvenObituaryList(evenObituaryListData);

          const oddObituaryListData =
            dataObituaryData?.memories?.items &&
            dataObituaryData.memories.items
              .sort((a, b) => a.ida_id - b.ida_id)
              .filter(function (element, index, array) {
                return index % 2 != 0;
              });
          setOddObituaryList(oddObituaryListData);
          fetch(process.env.FENIX_LOCATION_AJAX_URL + "_seo-scripts/memorial/");
        })
        .catch((error) => {
          // console.log("response error", error);
        });
    } else {
      //console.log("I AM HERE!!!", dataObituary);
      document.title =
        "In memory of " + dataObituary.name + " - Fenix Funeral Directors";
      props.setmeta(dataObituary);
      setSlideWidth((dataObituary?.memories?.items?.length / 1.7) * 750 + "px");
      const pointCount =
        (dataObituary.memories?.items?.length / 1.6) * 750 - 100;
      const evenObituaryListData =
        dataObituary?.memories?.items &&
        dataObituary.memories.items.filter(function (element, index, array) {
          return index % 2 === 0;
        });
      //console.log(slideWidth, dataObituary?.memories?.items?.length)
      setEvenObituaryList(evenObituaryListData);

      const oddObituaryListData =
        dataObituary?.memories?.items &&
        dataObituary.memories.items.filter(function (element, index, array) {
          return index % 2 != 0;
        });
      setOddObituaryList(oddObituaryListData);
      setDataObituary(dataObituary);

      if (dataObituary?.memories?.items) {
        for (let i = 0; i < dataObituary.memories.items.length; i++) {
          dataObituary.memories.items[i].itemIndex = i + 1;
        }
      }
      const likesExistant: any =
        window.localStorage.getItem("fenix_memory_likes");
      likesExistant && setUserLikes(likesExistant);
    }
  }, []);

  setTimeout(() => {
    setLikesArray(
      window.localStorage.getItem("fenix_memory_likes")
        ? window.localStorage
            .getItem("fenix_memory_likes")
            .split(",")
            .map(Number)
        : []
    );
  }, 300);

  useEffect(() => {
    let memoriesTop = [];
    let memoryIndex = 0;
    let lastHeight = 0;
    obituaryRefsMobile?.current.map((oneRef) => {
      oneRef?.offsetTop &&
        memoriesTop.push(oneRef?.offsetTop - 496 + memoryIndex * 2);
      lastHeight = oneRef?.offsetHeight;
      console.log("oneRef", oneRef?.offsetTop);
      memoryIndex++;
    });
    memoriesTop.push(memoriesTop[memoriesTop.length - 1] + lastHeight + 130);
    //console.log("memoriesTop", memoriesTop, slideHeight, slideWidth);
    memoriesTop.length > 0 && setMemoriesHeight(memoriesTop);
    buildWaveLine(dataObituary, memoriesTop);
  }, [dataObituary]);

  const condolences = null;

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      let names = [];
      const elements = document.querySelectorAll("[data-slide]");
      for (var i = 0; i < elements.length; i++) {
        let x = elements[i].getAttribute("data-slide");
        names.push(x);
      }
      return (
        '<span class="' +
        className +
        " " +
        names[index] +
        '"><span class="pagination-text">' +
        names[index] +
        "</span></span>"
      );
    },
  };

  const [obituaryRef2, size2] = useElementSize();
  const [obituaryRef3, size3] = useElementSize();
  const refLine = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(refLine, {});
  const isVisible = !!entry?.isIntersecting;

  function scrollTimeline(e) {
    const timeLineProgress = document.getElementById("timeLineProgress");
    let timeLineRow = document.getElementById("condolencesRow");
    const timeProgressBar = document.getElementById("timeProgressBar");
    if (timeLineRow && timeProgressBar && timeLineProgress) {
      const percentage = e.target.scrollLeft / (1400 - timeLineRow.offsetWidth);
      const leftPos = Math.round(
        (timeLineProgress.offsetWidth - 88) * percentage
      );
      timeProgressBar.style.left = leftPos + "px";
    }
  }

  useEffect(() => {
    const timeProgress = document.getElementById("timeProgressBar");

    if (timeProgress) timeProgress.style.width = "88px";
    let timelineEl = document.getElementById("condolencesRow");
    timelineEl?.addEventListener("scroll", scrollTimeline);

    return function () {
      timelineEl?.removeEventListener("scroll", scrollTimeline);
    };
  }, []);

  let memoriesRefs = useRef([]);
  let memoriesCircleRefs = useRef([]);
  let memoriesCoords = [];

  let startXtop = 130;
  let startXbottom = -210;

  const origin = {
    //origin of axes
    x: 100,
    y: 200,
  };
  const amplitude = 15; // wave amplitude
  const rarity = 1; // point spacing
  const freq = 0.00749; // angular frequency
  const phase = 0; // phase angle

  const clickShowImage = (items) => {
    setMemoryModalImageItem(items);
    setShowImageModal(true);
  };

  const clickMemoryImageClose = () => {
    setShowImageModal(false);
    document.body.classList.remove("modal-open");
  };

  const clickMemoryReadMore = (item) => {
    setMemoryModalItem(item);
    setShowMemoryModal(true);
  };

  const clickMemoryReadMoreClose = () => {
    setShowMemoryModal(false);
    document.body.classList.remove("modal-open");
  };

  const clickMemoryShareClose = () => {
    setShowShareModal(false);
    document.body.classList.remove("modal-open");
  };

  const clickReportClose = () => {
    setShowReportModal(false);
    document.body.classList.remove("modal-open");
  };

  const clickMemoryLike = (item) => {
    if (item.id) {
      const formData = new FormData();
      formData.append("memory_id", item.id);
      formData.append("memorial_id", item.memorial_id);
      const requestOptions = {
        method: "POST",
        body: formData,
      };
      fetch(
        process.env.FENIX_LOCATION_AJAX_URL + "api/memorials/submit-like/",
        requestOptions
      )
        .then((response) => response)
        .then((data) => {
          let likesStored: any = [];
          const likesExistant =
            window.localStorage.getItem("fenix_memory_likes");
          likesExistant && likesStored.push(likesExistant);
          likesStored.push(item.id);
          window.localStorage.setItem("fenix_memory_likes", likesStored);
          setUserLikes(likesStored);
        });
    }

    setTimeout(() => {
      setLikesArray(
        window.localStorage.getItem("fenix_memory_likes")
          ? window.localStorage
              .getItem("fenix_memory_likes")
              .split(",")
              .map(Number)
          : []
      );
    }, 300);
  };

  const clickMemoryReport = (item) => {
    setReportModalItem(item);
    setShowReportModal(true);
  };

  const clickShare = () => {
    setShowShareModal(true);
  };

  function setSectionSize(size) {
    useEffect(() => {
      if (slideHeight !== size.height && size.height > 0) {
        // console.log("setSectionSize", memoriesHeight, slideHeight, size.height);
        setSlideHeight(size.height);
      }
    }, [size]);
  }

  const buildWaveLine = (data: any, memoriesTop: any) => {
    let pointCount = Math.ceil(data?.memories?.items?.length / 2) * 860;
    if (!matches) pointCount = pointCount * 3;
    // console.log("buildWaveLine", memoriesTop, memoriesHeight, pointCount);
    var svg = document?.getElementById("sine_wave")?.children[0];
    let circleStartDone = false;
    let circleFinishDone;
    let lastPoint;
    let pointDesktop;

    if (svg && pointCount > 0) {
      let circleMemories: Array<number>;
      circleMemories = [];
      let circleCount = data.memories?.items?.length;
      //console.log("buildWaveLine", data, "|", memoriesTop, pointCount, circleCount);
      let circleNumber = 1;
      let circleOdd = 0;
      let circleEven = 0;
      let circleOddLast = 250 + 860 - 110 - 375;
      let circleEvenLast = 860 - 210 - 375;
      const pathDesktop = document?.getElementById("fenixLineDesktop");
      const pathMobile = document?.getElementById("fenixLineMobile");

      for (let i = 0; i < circleCount; i++) {
        if (i % 2 == 0) {
          // bottom
          circleEven++;
          circleMemories.push(circleEvenLast);
          circleEvenLast += 860;
        } else {
          circleOdd++;
          circleMemories.push(circleOddLast);
          circleOddLast += 860;
        }
      }

      if (matches) {
        if (circleEvenLast > circleOddLast) {
          pointCount = circleEvenLast - 480;
        } else if (circleEvenLast < circleOddLast) {
          pointCount = circleOddLast - 480;
        }
      }
      // if (!matches) pointCount = pointCount * 3;
      // console.log("circleMemories", circleMemories, circleEven, circleEvenLast, circleOdd, circleOddLast, pointCount);
      for (let i = -100; i < pointCount; i++) {
        const matchesCircleMobile =
          memoriesTop &&
          memoriesTop.filter((oneCircle) => {
            // console.log("filter compare ",pointCount, "|", i,oneCircle,i==oneCircle," | ", Math.ceil(i), Math.ceil(oneCircle), Math.ceil(i) == Math.ceil(oneCircle));
            return Math.ceil(i) == Math.ceil(oneCircle);
          })[0];
        // console.log("matchesCircleMobile",i,matchesCircleMobile,memoriesTop.length);
        let isLastCircle = false;
        let isFirstCircle = false;
        if (matchesCircleMobile == memoriesTop[memoriesTop.length - 1])
          isLastCircle = true;
        if (matchesCircleMobile == memoriesTop[0]) isFirstCircle = true;
        if (matchesCircleMobile && !matches) {
          //getPointAtLength
          // if (circleNumber > 1) {
          const point = pathMobile?.getPointAtLength(
            i - (circleNumber == 1 ? i + 10 : 0)
          );
          if (point) {
            let circle = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "circle"
            );

            if (isLastCircle) {
              circle.setAttribute("cx", String(point.x));
              circle.setAttribute("cy", String(point.y + 518));
              circle.setAttribute("r", "500");
              circle.setAttribute("fill", "#002733");
              circle.setAttribute("id", "crclmob_last2");
              svg.appendChild(circle);
            }

            circle = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "circle"
            );
            circle.setAttribute(
              "cx",
              String(point.x + (isFirstCircle ? 2 : 0))
            );
            circle.setAttribute(
              "cy",
              String(point.y - (isFirstCircle ? 12 : 0))
            );
            circle.setAttribute("r", "18");
            circle.setAttribute("fill", "#FFD86C");
            circle.setAttribute("id", "crclmob_" + circleNumber);
            svg.appendChild(circle);
          }
          // }
          circleNumber++;
        }

        const x1 = (i - 1) * rarity + origin.x;
        const y1 = Math.sin(freq * (i - 1 + phase)) * amplitude + origin.y;
        const x2 = i * rarity + origin.x;
        const y2 = Math.sin(freq * (i + phase)) * amplitude + origin.y;

        if (!circleStartDone && matches) {
          circleStartDone = true;
          let circle = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "circle"
          );
          circle.setAttribute("cx", String(x1 + 19 + (!matches ? 28 : 0)));
          circle.setAttribute("cy", String(y1 + 12));
          circle.setAttribute("r", "18");
          circle.setAttribute("fill", "#FFD86C");
          svg.appendChild(circle);
        }

        let line = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "line"
        );

        if (i % 430 == 0 && matches) {
          //getPointAtLength
          if (circleNumber > 1) {
            pointDesktop = pathDesktop?.getPointAtLength(
              i - (circleNumber % 2 == 0 ? 30 : 100)
            );
            //console.log("pathDesktop", pathDesktop);
            if (pointDesktop) {
              let circle = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "circle"
              );
              circle.setAttribute("cx", pointDesktop.x);
              circle.setAttribute("cy", pointDesktop.y);
              circle.setAttribute("r", "18");
              circle.setAttribute("fill", "#FFD86C");
              svg.appendChild(circle);

              let line = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "line"
              );
              line.setAttribute("x1", pointDesktop.x);
              line.setAttribute("y1", pointDesktop.y);
              line.setAttribute("x2", pointDesktop.x);
              line.setAttribute(
                "y2",
                String(pointDesktop.y + 200 * (circleNumber % 2 == 0 ? 1 : -1))
              );
              line.setAttribute("style", "stroke:#FFD86C;stroke-width:4px");
              svg.appendChild(line);
            }
          }
          circleNumber++;
        }

        const hasCircle = circleMemories.filter((el) => el == Math.ceil(x2));
        if (matches && hasCircle.length > 0 && false) {
          let circle = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "circle"
          );
          circle.setAttribute("cx", String(x2));
          circle.setAttribute(
            "cy",
            String(y2 + (circleNumber % 2 == 0 ? -7 : 7))
          );
          circle.setAttribute("r", "18");
          circle.setAttribute("fill", "#FFD86C");
          circle.setAttribute("id", String("crcl_" + circleNumber));
          svg.appendChild(circle);
          circleNumber++;

          let line = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "line"
          );
          line.setAttribute("x1", String(x2));
          line.setAttribute("y1", String(y2));

          line.setAttribute("x2", String(x2));
          line.setAttribute(
            "y2",
            String(y2 + 200 * (circleNumber % 2 == 0 ? 1 : -1))
          );

          line.setAttribute("style", "stroke:#FFD86C;strokeWidth:4px");
          svg.appendChild(line);
        }

        circleFinishDone = {
          x: x2,
          y: y2 + (circleEvenLast > circleOddLast ? -8 : 8),
        };
        lastPoint = i;
      }

      if (matches) {
        // lastPoint = Math.ceil(dataObituary.memories?.items?.length / 2) * 860 + (dataObituary.memories?.items?.length % 2 == 0 ? 480 - 218 : -88);
        lastPoint =
          Math.ceil(dataObituary.memories?.items?.length / 2) * 860 -
          (dataObituary.memories?.items?.length % 2 == 0 ? -150 : 150);
        // console.log("lastPoint2", lastPoint);

        pointDesktop = pathDesktop?.getPointAtLength(lastPoint);

        let circle = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "circle"
        );

        circle.setAttribute("cx", String(pointDesktop.x + 518));
        circle.setAttribute("cy", String(pointDesktop.y));
        circle.setAttribute("r", "500");
        circle.setAttribute("fill", "#002733");
        circle.setAttribute("id", "crcl_last2");
        svg.appendChild(circle);

        circle = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "circle"
        );
        circle.setAttribute("cx", String(pointDesktop.x));
        circle.setAttribute("cy", String(pointDesktop.y));
        circle.setAttribute("r", "18");
        circle.setAttribute("fill", "#FFD86C");
        circle.setAttribute("id", "crcl_last");
        svg.appendChild(circle);
      } else {
        const point = pathMobile?.getPointAtLength(slideHeight);
        // if (point) {
        //   let circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        //   circle.setAttribute('cx', point.x);
        //   circle.setAttribute('cy', point.y);
        //   circle.setAttribute('r', "18");
        //   circle.setAttribute('fill', "#FFD86C");
        //   circle.setAttribute('id', "crclmob_last");
        //   svg.appendChild(circle);
        // }
      }
    }
  };

  return (
    <LayoutHorizontal>
      {showImageModal && (
        <MemoryImageModal
          onClick={() => clickMemoryImageClose()}
          item={dataObituary?.memories.items}
        />
      )}
      {showMemoryModal && (
        <MemoryLongTextModal
          onClick={() => clickMemoryReadMoreClose()}
          item={memoryModalItem}
        />
      )}
      {showShareModal && (
        <ShareFormModal
          onClick={() => clickMemoryShareClose()}
          item={dataObituary?.url}
        />
      )}
      {showReportModal && (
        <ReportFormModal
          onClick={() => clickReportClose()}
          item={reportModalItem}
        />
      )}
      {matches ? (
        <Swiper
          id="obituarySwiper"
          className={
            isVisible
              ? "obituary-swiper-container obituary-swiper-container--visible"
              : "obituary-swiper-container"
          }
          modules={[FreeMode, Pagination, Mousewheel, Navigation]}
          navigation={true}
          pagination={pagination}
          grabCursor={true}
          slidesPerView={"auto"}
          freeMode={true}
          keyboard={true}
          allowTouchMove={true}
          simulateTouch={true}
          edgeSwipeDetection={true}
          touchReleaseOnEdges={true}
          mousewheel={{
            releaseOnEdges: true,
          }}
          breakpoints={{
            320: {
              direction: "vertical",
            },
            1200: {
              direction: "horizontal",
            },
          }}
          onInit={(swiper) => {
            swiper.navigation.nextEl.classList.add("show");
          }}
          onReachEnd={(swiper) => {
            swiper.navigation.prevEl.classList.add("show");
            swiper.navigation.nextEl.classList.remove("show");
          }}
          onReachBeginning={(swiper) => {
            swiper.navigation.prevEl.classList.remove("show");
            swiper.navigation.nextEl.classList.add("show");
          }}
        >
          <SwiperSlide
            className={
              "bg-deepblue pl-4 pr-4 pl-xl-0 pr-xl-0 section-container section-container--obituary d-flex align-items-end align-items-xl-center justify-content-center"
            }
            data-slide="start"
          >
            <a
              href={"/"}
              className="logo-link logo-link--obituary d-none d-xl-block"
            >
              <img
                src="/images/Logo-White.svg"
                className=""
                alt="Fenix funeral logo"
                height="50"
                width="180"
              />
            </a>
            <div className="obituary__icons d-none d-xl-block">
              <button className="action-share" onClick={() => clickShare()}>
                <img
                  src="/images/icons/icon-share.svg"
                  alt="icon share"
                  width="27"
                  height="27"
                />
              </button>
              {/*<button className="obituary__ring">*/}
              {/*  <img src="/images/icons/icon-ring.svg" alt="icon share" width={31} height={31}/>*/}
              {/*</button>*/}
            </div>
            <div className="single-obituary single-obituary--head text-white text-center mb-3 mb-xl-0">
              <img
                src="/images/icons/icon_flower-white.svg"
                alt="icon flower"
                width={68}
                height={68}
                className="mb-4"
              />
              <h1 className="single-obituary__title">{dataObituary?.name}</h1>
              <p className="single-obituary__dates">{dataObituary?.date}</p>
              {dataObituary?.time ? (
                <Link
                  to={dataObituary?.url + "registration/"}
                  className="memorial-content__funeral d-flex mx-auto justify-content-center align-items-center"
                >
                  Funeral{" "}
                  <span className="ml-2">
                    {new Date(dataObituary.time).toLocaleDateString("en", {
                      month: "short",
                      day: "numeric",
                    })}
                  </span>
                </Link>
              ) : (
                ""
              )}
            </div>
            <img
              src={dataObituary?.bg}
              loading="lazy"
              alt={dataObituary?.name}
              className="hero-gatsby--hero hero-gatsby--obituary w-100 h-100"
            />
          </SwiperSlide>
          <SwiperSlide
            className={
              "pl-0 pr-0 pl-xl-5 pr-xl-4 section-container section-container--info d-flex"
            }
            data-slide="about"
          >
            {dataObituary?.obituary.length < 250 ||
            !noFund.includes(dataObituary?.fund.title) ? (
              <div className="h-100 epitaph-row d-flex flex-nowrap">
                {!noFund.includes(dataObituary?.fund.title) &&
                !!dataObituary?.fund?.url ? (
                  <div className="pt-xl-4 pb-xl-4 d-flex align-items-center justify-content-center obituary-fund">
                    <DonateCard
                      fundDesc={dataObituary?.fund?.fund_desc}
                      fundTitle={dataObituary?.fund?.title}
                      fundImage={dataObituary?.fund?.image}
                      fundUrl={dataObituary?.fund?.url}
                    />
                  </div>
                ) : (
                  ""
                )}
                {dataObituary?.obituary.length < 250 && (
                  <div
                    className={
                      dataObituary?.fund.title &&
                      !noFund.includes(dataObituary?.fund.title)
                        ? "pl-xl-5 d-flex flex-column justify-content-center align-items-center epitaph-col"
                        : "d-flex flex-column justify-content-center align-items-center epitaph-col"
                    }
                  >
                    <p
                      className="epitaph-col--text text-left"
                      dangerouslySetInnerHTML={{
                        __html: dataObituary?.obituary
                          ? dataObituary?.obituary.replace(/\n/g, "<br />")
                          : "In loving memory of " +
                            dataObituary?.name.split(" ")[0] +
                            ".</br> Rest in Peace.",
                      }}
                    ></p>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            <div className="h-100 d-flex flex-column flex-xl-row">
              {dataObituary?.obituary.length > 250 && (
                <div className="h-100 obituary-about-text d-flex align-items-center">
                  <div className="d-flex flex-column justify-content-center align-items-start about-col text-white text-left pr-4 pl-4 pr-xl-0 pl-xl-0">
                    <h2 className="">
                      In loving memory of {dataObituary?.name.split(" ")[0]}.
                      <br /> Rest in Peace.
                    </h2>
                    <p
                      className="about-col--text"
                      dangerouslySetInnerHTML={{
                        __html: dataObituary?.obituary.replace(/\n/g, "<br />"),
                      }}
                    ></p>
                  </div>
                </div>
              )}
              <img
                src={dataObituary?.photo}
                alt={dataObituary?.name}
                className="hero-gatsby hero-gatsby--hero hero-gatsby--about h-100"
              />
            </div>
          </SwiperSlide>
          {/*<SwiperSlide className={"section-container pl-4 pr-4 section-container--action"} data-slide="cta">*/}
          {/*  <div className="h-100 mb-0">*/}
          {/*    <div className="h-100 d-flex flex-column justify-content-center">*/}
          {/*      <div className="single-obituary__cta mx-auto d-flex flex-column justify-content-center align-items-start">*/}
          {/*        <h2 className="text-white mb-4">Help us remember {dataObituary.name.split(" ")[0]}</h2>*/}
          {/*        <p className="text-white mb-5">Share your memories by uploading photos and texts to {dataObituary.name.split(" ")[0]}'s timeline.</p>*/}
          {/*        <div className="d-flex flex-column flex-xl-row align-items-center mx-auto mx-xl-0">*/}
          {/*          <ButtonModal*/}
          {/*            buttonClassname={"bg-mediumyellow text-deepblue single-add single-add--image mb-4 mb-xl-0"}*/}
          {/*            buttonTitle={"Share memory"}*/}
          {/*            buttonType={"modalMemory"}*/}
          {/*          />*/}
          {/*<ButtonModal*/}
          {/*  buttonClassname={"bg-mediumyellow text-deepblue single-add single-add--obituary mb-4 mb-xl-0"}*/}
          {/*  buttonTitle={"Condolence"}*/}
          {/*  buttonType={"modalCandle"}*/}
          {/*/>*/}
          {/*<ButtonModal*/}
          {/*  buttonClassname={"single-add single-add--candle"}*/}
          {/*  buttonTitle={"Light a candle"}*/}
          {/*  buttonType={"modalCandle"}*/}
          {/*/>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</SwiperSlide>*/}
          <SwiperSlide
            className={
              "section-container pr-4 pl-4 pl-xl-5 pr-xl-0 section-container--biography d-flex align-items-center justify-content-between"
            }
            style={{ minWidth: slideWidth }}
            data-slide="memories"
          >
            <div
              ref={obituaryRef2}
              className="h-100 mb-0 d-flex flex-column justify-content-center single-obituary__action"
            >
              <div className="single-obituary__cta mx-auto d-flex flex-column justify-content-center align-items-start">
                <h2 className="text-white mb-4">
                  Help us remember {dataObituary?.name.split(" ")[0]}
                </h2>
                <p className="text-white mb-5">
                  Share your memories by uploading photos and texts to{" "}
                  {dataObituary?.name.split(" ")[0]}'s timeline.
                </p>
                <div className="d-flex flex-column flex-xl-row align-items-center mx-auto mx-xl-0">
                  <ButtonModal
                    buttonClassname={
                      "bg-mediumyellow text-deepblue single-add single-add--image mb-4 mb-xl-0"
                    }
                    buttonTitle={"Share memory"}
                    buttonType={"modalMemory"}
                    data={dataObituary}
                  />
                  {/*<ButtonModal*/}
                  {/*  buttonClassname={"bg-mediumyellow text-deepblue single-add single-add--obituary mb-4 mb-xl-0"}*/}
                  {/*  buttonTitle={"Condolence"}*/}
                  {/*  buttonType={"modalCandle"}*/}
                  {/*/>*/}
                  {/*<ButtonModal*/}
                  {/*  buttonClassname={"single-add single-add--candle"}*/}
                  {/*  buttonTitle={"Light a candle"}*/}
                  {/*  buttonType={"modalCandle"}*/}
                  {/*/>*/}
                </div>
              </div>
            </div>
            <div
              className="biography biography--row flex-column justify-content-between d-none d-lg-flex"
              ref={refLine}
            >
              {dataObituary?.memories?.items && (
                <div className="biography-top d-flex">
                  <div
                    className={
                      "biography-block biography-block--text biography-block--start d-flex align-items-end"
                    }
                  >
                    <div className="biography-item biography-item--text d-flex flex-row">
                      <div className="biography-item__text">
                        <p className="mb-0">
                          {dataObituary?.date.split(" ")[0]}
                        </p>
                      </div>
                    </div>
                  </div>
                  {dataObituary?.memories?.items &&
                    dataObituary?.memories.items.length > 0 &&
                    oddObituaryList?.map(function (item: any, i: any) {
                      let itemText = item.text;
                      let hasReadMore = false;
                      if (item.text.length > maxLength) {
                        hasReadMore = true;
                        itemText = item.text.substr(0, maxLength);
                        itemText =
                          itemText.substr(
                            0,
                            Math.min(itemText.length, itemText.lastIndexOf(" "))
                          ) + "...";
                      }

                      return (
                        <div
                          key={i}
                          className={
                            item.text &&
                            item.text !== "FENIX_MEMORY_TEXT" &&
                            Object.values(item.media)[0]?.url
                              ? "biography-block biography-block--textimage"
                              : Object.values(item.media)[0]?.url &&
                                item.text === "FENIX_MEMORY_TEXT"
                              ? "biography-block biography-block--image"
                              : "biography-block biography-block--text bg-deepblue"
                          }
                        >
                          <div
                            className={
                              item.text &&
                              item.text !== "FENIX_MEMORY_TEXT" &&
                              Object.values(item.media)[0]?.url
                                ? "biography-item biography-item--textimage d-flex flex-row bg-white"
                                : item.media.length > 0 &&
                                  item.text === "FENIX_MEMORY_TEXT"
                                ? "biography-item biography-item--image d-flex flex-row bg-white"
                                : "biography-item biography-item--text d-flex flex-row text-white"
                            }
                          >
                            {item.text && item.text !== "FENIX_MEMORY_TEXT" && (
                              <div
                                className={
                                  item.text &&
                                  item.text !== "FENIX_MEMORY_TEXT" &&
                                  Object.values(item.media)[0]?.url
                                    ? "biography-item__text d-flex flex-column align-items-start justify-content-start"
                                    : "biography-item__text d-flex flex-column align-items-start justify-content-end"
                                }
                              >
                                <p
                                  className="mb-0"
                                  dangerouslySetInnerHTML={{ __html: itemText }}
                                ></p>
                                <div className="biography-item__actions">
                                  <button
                                    className="action-like"
                                    onClick={() => clickMemoryLike(item)}
                                  >
                                    <img
                                      className="mr-2 mb-1"
                                      src={
                                        item.text &&
                                        Object.values(item.media)[0]?.url &&
                                        likesArray.includes(item.id)
                                          ? "/images/icons/icon_heart-darkblue.svg"
                                          : item.text &&
                                            Object.values(item.media)[0]?.url
                                          ? "/images/icons/icon_heart-outline.svg"
                                          : (item.text &&
                                              likesArray.includes(item.id)) ||
                                            (Object.values(item.media)[0]
                                              ?.url &&
                                              likesArray.includes(item.id))
                                          ? "/images/icons/icon_heart-white.svg"
                                          : "/images/icons/icon_heart-outline-white.svg"
                                      }
                                      title="icon like"
                                      width={20}
                                      height={20}
                                    />
                                    Like
                                  </button>
                                  <button
                                    className="action-share"
                                    onClick={() => clickShare()}
                                  >
                                    Share
                                  </button>
                                  <button
                                    className="action-report"
                                    onClick={() => clickMemoryReport(item)}
                                  >
                                    Report
                                  </button>
                                </div>
                                {hasReadMore && (
                                  <button
                                    className="biography-item__more text-darkblue"
                                    onClick={() => clickMemoryReadMore(item)}
                                  >
                                    Read more...
                                  </button>
                                )}
                                <b
                                  className="text-darkblue"
                                  dangerouslySetInnerHTML={{
                                    __html: item.name,
                                  }}
                                ></b>
                              </div>
                            )}
                            {Object.values(item.media)[0]?.url && (
                              <div
                                className={
                                  Object.values(item.media)[0]?.url &&
                                  item.text &&
                                  item.text !== "FENIX_MEMORY_TEXT"
                                    ? "biography-item__image"
                                    : "biography-item__image w-100"
                                }
                              >
                                <img
                                  src={Object.values(item.media)[0]?.url}
                                  alt="text"
                                  onClick={() =>
                                    clickShowImage(dataObituary?.memories.items)
                                  }
                                />
                                {Object.values(item.media)[0]?.url &&
                                item.text &&
                                item.text !== "FENIX_MEMORY_TEXT" ? (
                                  ""
                                ) : (
                                  <b
                                    className="text-mediumyellow"
                                    dangerouslySetInnerHTML={{
                                      __html: item.name,
                                    }}
                                  ></b>
                                )}
                              </div>
                            )}
                            {Object.values(item.media)[0]?.url &&
                            item.text &&
                            item.text !== "FENIX_MEMORY_TEXT" ? (
                              ""
                            ) : (
                              <div className="biography-item__actions">
                                <button
                                  className="action-like"
                                  onClick={() => clickMemoryLike(item)}
                                >
                                  <img
                                    className="mr-2 mb-1"
                                    src={
                                      Object.values(item.media)[0]?.url &&
                                      likesArray.includes(item.id)
                                        ? "/images/icons/icon_heart-white.svg"
                                        : Object.values(item.media)[0]?.url
                                        ? "/images/icons/icon_heart-outline-white.svg"
                                        : likesArray.includes(item.id) ||
                                          (Object.values(item.media)[0]?.url &&
                                            likesArray.includes(item.id))
                                        ? "/images/icons/icon_heart-white.svg"
                                        : "/images/icons/icon_heart-outline-white.svg"
                                    }
                                    title="icon like"
                                    width={20}
                                    height={20}
                                  />
                                  Like
                                </button>
                                <button
                                  className="action-share"
                                  onClick={() => clickShare()}
                                >
                                  Share
                                </button>
                                <button
                                  className="action-report"
                                  onClick={() => clickMemoryReport(item)}
                                >
                                  Report
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {oddObituaryList?.length >= evenObituaryList?.length && (
                    <div
                      className={
                        "biography-block biography-block--text biography-block--end"
                      }
                    >
                      <div className="biography-item biography-item--text">
                        <div className="biography-item__text">
                          <p
                            className="mb-0"
                            style={{ textAlign: "right", marginRight: "48px" }}
                          >
                            {dataObituary.date.split(" ").pop()}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {dataObituary?.memories?.items && (
                <div className="biography-line">
                  <div
                    id="sine_wave"
                    style={{
                      width: "800px",
                      height: "800px",
                      position: "relative",
                      //top: "-100px",
                      left: "100px",
                    }}
                  >
                    <svg
                      style={{
                        width:
                          String(
                            Math.ceil(
                              dataObituary.memories?.items?.length / 2
                            ) *
                              860 +
                              (dataObituary.memories?.items?.length % 2 == 0
                                ? 480 - 218
                                : -88)
                          ) + "px",
                        // width: String(Math.ceil(dataObituary.memories?.items?.length / 2) * 860 + (dataObituary.memories?.items?.length % 2 !== 0 ? 750 : 0)) + "px",
                        height: "400px",
                        position: "absolute",
                        top: "-140px",
                        left: 0,
                        zIndex: -1000,
                      }}
                    >
                      <path
                        id="fenixLineDesktop"
                        d="
                        M 0 200 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                        m 0 0 q 215 -40 430 0,
                        m 0 0 q 215 40 430 0,
                      "
                        stroke="#FFD86C"
                        strokeWidth="4"
                        fill="none"
                      />
                    </svg>
                  </div>
                </div>
              )}
              {dataObituary?.memories?.items && (
                <div className="biography-bottom">
                  {dataObituary?.memories.items.length > 0 &&
                    evenObituaryList?.map(function (item: any, i: any) {
                      let itemText = item.text;
                      let hasReadMore = false;
                      if (item.text.length > maxLength) {
                        hasReadMore = true;
                        itemText = item.text.substr(0, maxLength);
                        itemText =
                          itemText.substr(
                            0,
                            Math.min(itemText.length, itemText.lastIndexOf(" "))
                          ) + "...";
                      }
                      return (
                        <div
                          key={i}
                          className={
                            item.text &&
                            item.text !== "FENIX_MEMORY_TEXT" &&
                            Object.values(item.media)[0]?.url
                              ? "biography-block biography-block--textimage"
                              : Object.values(item.media)[0]?.url &&
                                item.text === "FENIX_MEMORY_TEXT"
                              ? "biography-block biography-block--image"
                              : "biography-block biography-block--text"
                          }
                        >
                          <div
                            className={
                              item.text &&
                              item.text !== "FENIX_MEMORY_TEXT" &&
                              Object.values(item.media)[0]?.url
                                ? "biography-item biography-item--textimage d-flex flex-row bg-white"
                                : item.media.length > 0 &&
                                  item.text === "FENIX_MEMORY_TEXT"
                                ? "biography-item biography-item--image d-flex flex-row bg-white"
                                : "biography-item biography-item--text d-flex flex-row text-white bg-deepblue"
                            }
                          >
                            {item.text && item.text !== "FENIX_MEMORY_TEXT" && (
                              <div className="biography-item__text d-flex flex-column align-items-start justify-content-start">
                                <p
                                  className="mb-0"
                                  dangerouslySetInnerHTML={{ __html: itemText }}
                                ></p>
                                <div className="biography-item__actions">
                                  <button
                                    className="action-like"
                                    onClick={() => clickMemoryLike(item)}
                                  >
                                    <img
                                      className="mr-2 mb-1"
                                      src={
                                        item.text &&
                                        Object.values(item.media)[0]?.url &&
                                        likesArray.includes(item.id)
                                          ? "/images/icons/icon_heart-darkblue.svg"
                                          : item.text &&
                                            Object.values(item.media)[0]?.url
                                          ? "/images/icons/icon_heart-outline.svg"
                                          : (item.text &&
                                              likesArray.includes(item.id)) ||
                                            (Object.values(item.media)[0]
                                              ?.url &&
                                              likesArray.includes(item.id))
                                          ? "/images/icons/icon_heart-white.svg"
                                          : "/images/icons/icon_heart-outline-white.svg"
                                      }
                                      title="icon like"
                                      width={20}
                                      height={20}
                                    />
                                    Like
                                  </button>
                                  <button
                                    className="action-share"
                                    onClick={() => clickShare()}
                                  >
                                    Share
                                  </button>
                                  <button
                                    className="action-report"
                                    onClick={() => clickMemoryReport(item)}
                                  >
                                    Report
                                  </button>
                                </div>
                                {hasReadMore && (
                                  <button
                                    className="biography-item__more text-darkblue"
                                    onClick={() => clickMemoryReadMore(item)}
                                  >
                                    Read more...
                                  </button>
                                )}
                                <b
                                  className="text-darkblue"
                                  dangerouslySetInnerHTML={{
                                    __html: item.name,
                                  }}
                                ></b>
                              </div>
                            )}
                            {Object.values(item.media)[0]?.url && (
                              <div
                                className={
                                  Object.values(item.media)[0]?.url &&
                                  item.text &&
                                  item.text !== "FENIX_MEMORY_TEXT"
                                    ? "biography-item__image"
                                    : "biography-item__image w-100"
                                }
                              >
                                <img
                                  src={Object.values(item.media)[0]?.url}
                                  alt="text"
                                  onClick={() =>
                                    clickShowImage(dataObituary?.memories.items)
                                  }
                                />
                                {Object.values(item.media)[0]?.url &&
                                item.text ? (
                                  ""
                                ) : (
                                  <b
                                    className="text-mediumyellow"
                                    dangerouslySetInnerHTML={{
                                      __html: item.name,
                                    }}
                                  ></b>
                                )}
                              </div>
                            )}
                            {Object.values(item.media)[0]?.url &&
                            item.text &&
                            item.text !== "FENIX_MEMORY_TEXT" ? (
                              ""
                            ) : (
                              <div className="biography-item__actions">
                                <button
                                  className="action-like"
                                  onClick={() => clickMemoryLike(item)}
                                >
                                  <img
                                    className="mr-2 mb-1"
                                    src={
                                      Object.values(item.media)[0]?.url &&
                                      likesArray.includes(item.id)
                                        ? "/images/icons/icon_heart-white.svg"
                                        : Object.values(item.media)[0]?.url
                                        ? "/images/icons/icon_heart-outline-white.svg"
                                        : likesArray.includes(item.id) ||
                                          (Object.values(item.media)[0]?.url &&
                                            likesArray.includes(item.id))
                                        ? "/images/icons/icon_heart-white.svg"
                                        : "/images/icons/icon_heart-outline-white.svg"
                                    }
                                    title="icon like"
                                    width={20}
                                    height={20}
                                  />
                                  Like
                                </button>
                                <button
                                  className="action-share"
                                  onClick={() => clickShare()}
                                >
                                  Share
                                </button>
                                <button
                                  className="action-report"
                                  onClick={() => clickMemoryReport(item)}
                                >
                                  Report
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {evenObituaryList?.length > oddObituaryList?.length && (
                    <div
                      className={
                        "biography-block biography-block--text biography-block--end"
                      }
                    >
                      <div className="biography-item biography-item--text">
                        <div className="biography-item__text">
                          <p
                            className="mb-0"
                            style={{ textAlign: "right", marginRight: "48px" }}
                          >
                            {dataObituary.date.split(" ").pop()}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <img
              ref={obituaryRef3}
              src={dataObituary?.bg}
              alt={dataObituary?.name}
              className="hero-gatsby hero-gatsby--hero hero-gatsby--headstone h-100"
            />
          </SwiperSlide>
          {/*{condolences && <SwiperSlide className={"section-container pr-4 pl-4 pl-xl-5 pr-xl-5 section-container--memo"}*/}
          {/*                             data-slide="condolences">*/}
          {/*  <div className="h-100 d-flex flex-column justify-content-center">*/}
          {/*    <div className="single-memo__grid text-white mt-auto mb-auto h-100 pr-5">*/}
          {/*      {condolences.length > 0 && condolences.map(function (item: any, i: any) {*/}
          {/*        return <div key={i}*/}
          {/*                    className={item.type === "candle" ? "single-memo__item single-memo__candle d-flex flex-column mb-3" : "single-memo__item single-memo__text d-flex flex-column justify-content-center mb-2"}>{item.type === "candle" ?*/}
          {/*          <span>Tänt av</span> : ""}{item.condolencesText}</div>*/}
          {/*      })}*/}
          {/*    </div>*/}
          {/*    <div className="single-memo__bg1">*/}
          {/*      <img src={dataObituary.photo} alt={dataObituary.bg}*/}
          {/*           className="hero-gatsby hero-gatsby--hero hero-gatsby__condolences w-100 h-100"/>*/}
          {/*    </div>*/}
          {/*    <div className="single-memo__bg2">*/}
          {/*      <img src={dataObituary.bg} alt={dataObituary.bg}*/}
          {/*           className="hero-gatsby hero-gatsby--hero hero-gatsby__condolences w-100 h-100"/>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</SwiperSlide>}*/}
          {/*<SwiperSlide className={"section-container section-container--headstone"} data-slide="headstone">*/}
          {/* {buildWaveLine(dataObituary)} */}
          {/*</SwiperSlide>*/}
        </Swiper>
      ) : (
        <div className="memorial-mobile">
          <Section
            sectionClass={
              "bg-deepblue pl-4 pr-4 pl-xl-0 pr-xl-0 section-container section-container--obituary d-flex align-items-end align-items-xl-center justify-content-center"
            }
            data-slide="start"
          >
            <a href={"/"} className="logo-link logo-link--obituary">
              <img
                src="/images/Logo-White.svg"
                className="logo-svg"
                alt="Fenix funeral logo"
                height="50"
                width="180"
              />
            </a>
            <div className="obituary__icons d-none d-xl-block">
              <button className="action-share" onClick={() => clickShare()}>
                Share
              </button>
              <button className="obituary__ring">
                <img
                  src="/images/icons/icon-ring.svg"
                  alt="icon share"
                  width={31}
                  height={31}
                />
              </button>
            </div>
            <div className="single-obituary single-obituary--head text-white text-center mb-3 mb-xl-0">
              <img
                src="/images/icons/icon_flower-white.svg"
                alt="icon flower"
                width={68}
                height={68}
                className="mb-4"
              />
              <h1 className="single-obituary__title">{dataObituary?.name}</h1>
              <p className="single-obituary__dates">{dataObituary?.date}</p>
              {dataObituary?.time ? (
                <Link
                  to={dataObituary?.url + "registration/"}
                  className="memorial-content__funeral d-flex mx-auto justify-content-center align-items-center"
                >
                  Funeral{" "}
                  <span className="ml-2">
                    {new Date(dataObituary.time).toLocaleDateString("en", {
                      month: "short",
                      day: "numeric",
                    })}
                  </span>
                </Link>
              ) : (
                ""
              )}
            </div>
            <img
              src={dataObituary?.bg}
              loading="lazy"
              alt={dataObituary?.name}
              className="hero-gatsby--hero hero-gatsby--obituary w-100 h-100"
            />
          </Section>
          <Section
            sectionClass={
              "pl-0 pr-0 pl-xl-5 pr-xl-4 section-container section-container--info d-flex flex-column"
            }
          >
            {dataObituary?.obituary.length < 250 ||
            !noFund.includes(dataObituary?.fund.title) ? (
              <div className="row no-gutters h-100 epitaph-row pl-4 pr-4">
                {!noFund.includes(dataObituary?.fund.title) &&
                !!dataObituary?.fund?.url ? (
                  <div className="col-12 d-flex align-items-center justify-content-center obituary-fund">
                    <DonateCard
                      fundDesc={dataObituary?.fund?.fund_desc}
                      fundTitle={dataObituary?.fund?.title}
                      fundImage={dataObituary?.fund?.image}
                      fundUrl={dataObituary?.fund?.url}
                    />
                  </div>
                ) : (
                  ""
                )}
                {dataObituary?.obituary.length < 250 && (
                  <div
                    className={
                      dataObituary?.fund.title &&
                      !noFund.includes(dataObituary?.fund.title)
                        ? "col-12 col-xl-7 pl-xl-5 d-flex flex-column justify-content-center align-items-center epitaph-col mx-auto"
                        : "col-12 pt-xl-5 d-flex flex-column justify-content-center align-items-center epitaph-col pl-4 pr-4"
                    }
                  >
                    <p
                      className="epitaph-col--text text-right"
                      dangerouslySetInnerHTML={{
                        __html: dataObituary?.obituary
                          ? dataObituary?.obituary.replace(/\n/g, "<br />")
                          : "In loving memory of " +
                            dataObituary?.name.split(" ")[0] +
                            ".</br> Rest in Peace.",
                      }}
                    ></p>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            <div className="h-100 d-flex flex-column flex-xl-row w-100 mt-4 mb-3">
              {dataObituary?.obituary.length > 250 && (
                <div className="h-100 obituary-about-text d-flex align-items-center mx-auto">
                  <div className="d-flex flex-column justify-content-center align-items-start about-col text-white text-left pr-4 pl-4 pr-xl-0 pl-xl-0">
                    <h2 className="">
                      In loving memory of {dataObituary?.name.split(" ")[0]}.
                      <br /> Rest in Peace.
                    </h2>
                    <p
                      className="about-col--text"
                      dangerouslySetInnerHTML={{
                        __html: dataObituary?.obituary.replace(/\n/g, "<br />"),
                      }}
                    ></p>
                  </div>
                </div>
              )}
              <img
                src={dataObituary?.photo}
                alt={dataObituary?.name}
                className="hero-gatsby hero-gatsby--hero hero-gatsby--about w-100"
              />
            </div>
          </Section>
          <Section
            sectionClass={
              "section-container pr-4 pl-4 pl-xl-5 pr-xl-5 section-container--biography d-flex flex-column align-items-center"
            }
            data-slide="memories"
            setSize={(size) => setSectionSize(size)}
          >
            <div className="h-100 mb-0 d-flex flex-column justify-content-center single-obituary__action">
              <div className="single-obituary__cta mx-auto d-flex flex-column justify-content-center align-items-start">
                <h2 className="text-white mb-4">
                  Help us remember {dataObituary?.name.split(" ")[0]}
                </h2>
                <p className="text-white mb-5">
                  Share your memories by uploading photos and texts to{" "}
                  {dataObituary?.name.split(" ")[0]}'s timeline.
                </p>
                <div className="d-flex flex-column flex-xl-row align-items-center mx-auto mx-xl-0">
                  <ButtonModal
                    buttonClassname={
                      "bg-mediumyellow text-deepblue single-add single-add--image mb-4 mb-xl-0"
                    }
                    buttonTitle={"Share memory"}
                    buttonType={"modalMemory"}
                    data={dataObituary}
                  />
                  {/*<ButtonModal*/}
                  {/*  buttonClassname={"bg-mediumyellow text-deepblue single-add single-add--obituary mb-4 mb-xl-0"}*/}
                  {/*  buttonTitle={"Condolence"}*/}
                  {/*  buttonType={"modalCandle"}*/}
                  {/*/>*/}
                  {/*<ButtonModal*/}
                  {/*  buttonClassname={"single-add single-add--candle"}*/}
                  {/*  buttonTitle={"Light a candle"}*/}
                  {/*  buttonType={"modalCandle"}*/}
                  {/*/>*/}
                </div>
              </div>
            </div>
            {dataObituary?.memories?.items && (
              <div className="biography biography--column flex-column justify-content-between d-flex d-lg-flex w-100">
                <div
                  className={
                    "biography-block biography-block--text biography-block--start mb-5"
                  }
                  style={{ paddingLeft: 105 }}
                >
                  <div className="biography-item biography-item--text d-flex flex-row">
                    <div className="biography-item__text">
                      <p className="mb-0">{dataObituary.date.split(" ")[0]}</p>
                    </div>
                  </div>
                </div>
                {dataObituary?.memories?.items && (
                  <div
                    className="biography-line"
                    style={{ top: "270px" }}
                    // style={!matches ? { transform: "rotate(90deg)", top: "3250px", left: "-2810px", } : {}}
                  >
                    <div
                      id="sine_wave"
                      style={{
                        width: "800px",
                        height: "800px",
                        position: "relative",
                        left: "30px",
                      }}
                    >
                      <svg
                        style={{
                          height: String(slideHeight) + "px",
                          // height: String(Math.ceil(dataObituary.memories?.items?.length / 2) * 860  + (dataObituary.memories?.items?.length % 2 == 0 ? 480 : 0)) + "px",
                          // width: String(Math.ceil(dataObituary.memories?.items?.length / 2) * 860 + (dataObituary.memories?.items?.length % 2 !== 0 ? 750 : 0)) + "px",
                          width: "400px",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          zIndex: -1000,
                        }}
                      >
                        <path
                          id="fenixLineMobile"
                          d="
                        M 50 200 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                        m 0 0 q -40 215 0 430,
                        m 0 0 q 40 215 0 430,
                      "
                          stroke="#FFD86C"
                          strokeWidth="4"
                          fill="none"
                        />
                      </svg>
                    </div>
                  </div>
                )}
                {dataObituary.memories?.items.length > 0 &&
                  dataObituary.memories?.items.map(function (
                    item: any,
                    i: any
                  ) {
                    let itemText = item.text;
                    let hasReadMore = false;
                    if (item.text.length > maxLengthMobile) {
                      hasReadMore = true;
                      itemText = item.text.substr(0, maxLengthMobile);
                      itemText =
                        itemText.substr(
                          0,
                          Math.min(itemText.length, itemText.lastIndexOf(" "))
                        ) + "...";
                    }
                    return (
                      <div
                        key={i}
                        ref={(el) => {
                          obituaryRefsMobile.current[i] = el;
                        }}
                        className={
                          item.text &&
                          item.text !== "FENIX_MEMORY_TEXT" &&
                          Object.values(item.media)[0]?.url
                            ? "biography-block biography-block--textimage"
                            : Object.values(item.media)[0]?.url &&
                              item.text === "FENIX_MEMORY_TEXT"
                            ? "biography-block biography-block--image"
                            : "biography-block biography-block--text bg-deepblue-lighter"
                        }
                      >
                        <div
                          className={
                            item.text &&
                            item.text !== "FENIX_MEMORY_TEXT" &&
                            Object.values(item.media)[0]?.url
                              ? "biography-item biography-item--textimage d-flex flex-column-reverse flex-xl-row bg-white"
                              : item.media.length > 0 &&
                                item.text === "FENIX_MEMORY_TEXT"
                              ? "biography-item biography-item--image d-flex flex-column flex-xl-row bg-white"
                              : "biography-item biography-item--text d-flex flex-column flex-xl-row text-white"
                          }
                        >
                          {itemText && item.text !== "FENIX_MEMORY_TEXT" && (
                            <div className="biography-item__text d-flex flex-column">
                              <p
                                dangerouslySetInnerHTML={{ __html: itemText }}
                              ></p>
                              {hasReadMore && (
                                <button
                                  className="biography-item__more text-darkblue text-left"
                                  onClick={() => clickMemoryReadMore(item)}
                                >
                                  Read more...
                                </button>
                              )}
                              <b
                                className="text-darkblue"
                                dangerouslySetInnerHTML={{ __html: item.name }}
                              ></b>
                              <div className="biography-item__actions">
                                <button
                                  className="action-like"
                                  onClick={() => clickMemoryLike(item)}
                                >
                                  <img
                                    className="mr-2 mb-1"
                                    src={
                                      item.text &&
                                      Object.values(item.media)[0]?.url &&
                                      likesArray.includes(item.id)
                                        ? "/images/icons/icon_heart-darkblue.svg"
                                        : item.text &&
                                          Object.values(item.media)[0]?.url
                                        ? "/images/icons/icon_heart-outline.svg"
                                        : (item.text &&
                                            likesArray.includes(item.id)) ||
                                          (Object.values(item.media)[0]?.url &&
                                            likesArray.includes(item.id))
                                        ? "/images/icons/icon_heart-white.svg"
                                        : "/images/icons/icon_heart-outline-white.svg"
                                    }
                                    title="icon like"
                                    width={20}
                                    height={20}
                                  />
                                  Like
                                </button>
                                <button
                                  className="action-share"
                                  onClick={() => clickShare()}
                                >
                                  Share
                                </button>
                                <button
                                  className="action-report"
                                  onClick={() => clickMemoryReport(item)}
                                >
                                  Report
                                </button>
                              </div>
                            </div>
                          )}
                          {Object.values(item.media)[0]?.url && (
                            <div
                              className={
                                Object.values(item.media)[0]?.url &&
                                item.text &&
                                item.text !== "FENIX_MEMORY_TEXT"
                                  ? "biography-item__image"
                                  : "biography-item__image w-100"
                              }
                            >
                              <img
                                src={Object.values(item.media)[0]?.url}
                                alt="text"
                                onClick={() =>
                                  clickShowImage(dataObituary?.memories.items)
                                }
                              />
                              {Object.values(item.media)[0]?.url &&
                              item.text &&
                              item.text !== "FENIX_MEMORY_TEXT" ? (
                                ""
                              ) : (
                                <b
                                  className="text-mediumyellow"
                                  dangerouslySetInnerHTML={{
                                    __html: item.name,
                                  }}
                                ></b>
                              )}
                              {Object.values(item.media)[0]?.url &&
                              item.text &&
                              item.text !== "FENIX_MEMORY_TEXT" ? (
                                ""
                              ) : (
                                <div className="biography-item__actions">
                                  <button
                                    className="action-like"
                                    onClick={() => clickMemoryLike(item)}
                                  >
                                    <img
                                      className="mr-2 mb-1"
                                      src={
                                        Object.values(item.media)[0]?.url &&
                                        likesArray.includes(item.id)
                                          ? "/images/icons/icon_heart-darkblue.svg"
                                          : Object.values(item.media)[0]?.url
                                          ? "/images/icons/icon_heart-outline.svg"
                                          : likesArray.includes(item.id) ||
                                            (Object.values(item.media)[0]
                                              ?.url &&
                                              likesArray.includes(item.id))
                                          ? "/images/icons/icon_heart-white.svg"
                                          : "/images/icons/icon_heart-outline-white.svg"
                                      }
                                      title="icon like"
                                      width={20}
                                      height={20}
                                    />
                                    Like
                                  </button>
                                  <button
                                    className="action-share"
                                    onClick={() => clickShare()}
                                  >
                                    Share
                                  </button>
                                  <button
                                    className="action-report"
                                    onClick={() => clickMemoryReport(item)}
                                  >
                                    Report
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="biography-item__dot d-none">
                          <svg
                            id={"ref" + item.itemIndex}
                            width="36"
                            height="35"
                            viewBox="0 0 36 35"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="17.6016"
                              cy="17.5"
                              r="17.5"
                              fill="#FFD86C"
                            />
                          </svg>
                        </div>
                      </div>
                    );
                  })}
                <div
                  className={
                    "biography-block biography-block--text biography-block--end"
                  }
                >
                  <div className="biography-item biography-item--text">
                    <div className="biography-item__text">
                      <p
                        className="mb-0"
                        style={{ textAlign: "right", marginRight: "48px" }}
                      >
                        {dataObituary.date.split(" ").pop()}
                      </p>
                    </div>
                  </div>
                  <div
                    id={"ref" + (dataObituary.memories.items.length + 1)}
                    className="biography-item__dot d-none"
                  >
                    <svg
                      width="36"
                      height="35"
                      viewBox="0 0 36 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="17.6016" cy="17.5" r="17.5" fill="#FFD86C" />
                    </svg>
                  </div>
                </div>
              </div>
            )}
          </Section>
          <Section
            sectionClass={"section-container section-container--headstone"}
            data-slide="headstone"
          >
            <img
              src={dataObituary?.bg}
              alt={dataObituary?.name}
              className="hero-gatsby hero-gatsby--hero hero-gatsby--headstone w-100 h-100"
            />
          </Section>
        </div>
      )}
    </LayoutHorizontal>
  );
};

export default SingleObituary;
